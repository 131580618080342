<script>
import Swal from "sweetalert2";

import Layout from "../../layouts/main.vue";
import axios from "axios";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      name: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      image: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
        isValid: true,
      },
      address: {
        val: "",
        isValid: true,
      },
      expert: {
        val: "",
        isValid: true,
      },
      degree: {
        val: "",
        isValid: true,
      },
      status: {
        val: "",
        isValid: true,
      },
      doctor: [],
      id: "",
      formIsValid: true,
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true
    },
    reloadPage() {
      window.location.reload();
    },
    validateForm() {
      if (this.name.val === "") {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (this.email.val === "") {
        this.email.isValid = false;
        this.formIsValid = false;
      }
      if (this.image.val === "") {
        this.image.isValid = false;
        this.formIsValid = false;
      }
      if (this.phone.val === "") {
        this.phone.isValid = false;
        this.formIsValid = false;
      }
      if (this.address.val === "") {
        this.address.isValid = false;
        this.formIsValid = false;
      }
      if (this.expert.val === "") {
        this.expert.isValid = false;
        this.formIsValid = false;
      }
      if (this.degree.val === "") {
        this.degree.isValid = false;
        this.formIsValid = false;
      }
      if (this.status.val === "") {
        this.status.isValid = false;
        this.formIsValid = false;
      }
      else {
        this.formIsValid = true;
      }
    },
    async getDoctor() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/doctor/"
      );
      this.doctor = response.data;
      const lastEl = this.doctor[this.doctor.length - 1];
      this.id = lastEl.id;
      this.successmsg(this.id);
    },

    async addDoctor() {
      this.validateForm();
      if (!this.formIsValid) {
        this.errormsg();
        return;
      } else {
        var formdata = new FormData();
        formdata.append("name", this.name.val);
        formdata.append("email", this.email.val);
        formdata.append("image", this.$refs.fileInput.files[0]);
        formdata.append("phone", this.phone.val);
        formdata.append("address", this.address.val);
        formdata.append("expert", this.expert.val);
        formdata.append("degree", this.degree.val);
        formdata.append("status", this.status.val);

        const result = await axios.post(
          "https://api.doctosoft.com/index.php/api/doctor",
          formdata
        );
        this.msg = result.data;
        this.getDoctor();
        this.$router.push("/admin/doctor");
      }
    },

    errormsg() {
      Swal.fire(
        "Invalid Form",
        "Please fix the errors and try again later",
        "error"
      );
    },
    successmsg() {
      Swal.fire(
        "Good job!",
        "Created new doctor successfully " ,
        "success"
      );
    },
  },
};
</script>
<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/doctor')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/doctor')">Cancel</b-button>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="addDoctor">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="addDoctor">
            <div class="mb-3" :class="{ invalid: !name.isValid }">
              <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="name.val" placeholder="Enter name"
                @blur="clearValidity('name')" />
            </div>

            <div class="mb-3" :class="{ invalid: !email.isValid }">
              <label for="email" class="form-label">Email <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="email.val" placeholder="Enter Email"
                @blur="clearValidity('email')" />
            </div>

            <div class="mb-3">
              <label for="image" class="form-label">Image <span class="text-danger">*</span></label>
              <input type="file"  ref="fileInput" class="form-control" placeholder="Enter image"
                @blur="clearValidity('image')" />
            </div>

            <div class="mb-3" :class="{ invalid: !phone.isValid }">
              <label for="phone" class="form-label">Phone <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="phone.val" placeholder="Enter phone"
                @blur="clearValidity('phone')" />
            </div>

            <div class="mb-3" :class="{ invalid: !address.isValid }">
              <label for="address" class="form-label">Address <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="address.val" placeholder="Enter address"
                @blur="clearValidity('address')" />
            </div>

            <div class="mb-3" :class="{ invalid: !expert.isValid }">
              <label for="expert" class="form-label">Expert <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="expert.val" placeholder="Enter expert"
                @blur="clearValidity('expert')" />
            </div>

            <div class="mb-3" :class="{ invalid: !degree.isValid }">
              <label for="degree" class="form-label">Degree <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="degree.val" placeholder="Enter degree"
                @blur="clearValidity('degree')" />
            </div>

            <div class="mb-3" :class="{ invalid: !status.isValid }">
              <label for="status" class="form-label">Status <span class="text-danger">*</span></label>
              <select class="form-select" aria-label="size 3 select example" v-model="status.val"
                @blur="clearValidity('status')">
                <option value="true">Enable</option>
                <option value="false">Disable</option>
              </select>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
